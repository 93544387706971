.container{
    display: flex;
    justify-content: center;
    font-family: Avenir;
    cursor: pointer;
    min-width: 250px;
    max-width: 450px;
}
.card{
    background-color: white;
    width:90%;
    padding: 15px;
    box-sizing: border-box;
    box-shadow: 0px 2px 10px lightgray;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
}
.card img{
    width: 100%;
}
.card .name{
    font-size: 2rem;
    margin: 30px 0 10px 0;
}
.card:hover .actions{
    opacity: 1;
}
.card .actions{
    transition:all .3s;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
}

.actions .left{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: rgba(189, 195, 199, .7);

}
.actions .right{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: rgba(246, 36, 89, .7);
}

.actions span{
    color:white;
    font-size: 6rem;
    transition:all .3s;

}
.actions .right:hover span, .actions .left:hover span{
    font-size: 7rem;
}